import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { AdminService } from 'src/app/service/admin.service';
import { Inicio } from 'src/app/interface/inicio';




@Component({
  selector: 'app-adinicio',
  templateUrl: './adinicio.component.html',
  styleUrls: ['./adinicio.component.scss']
})
export class AdinicioComponent implements OnInit {

  adinicio: Inicio[];

  formTemplate = new FormGroup({
    titulo: new FormControl(''),
    subtitulo: new FormControl(''),
    topico1: new FormControl(''),
    topico2: new FormControl(''),
    topico3: new FormControl(''),
  })

  constructor(
    private storage: AngularFireStorage,
    private adminService: AdminService
  ) {
   }

  ngOnInit(): void {
   console.log(this.adminService.getDetalhes())
  }

  

  updateInicioNoticia(formvalue){
    //console.log(formvalue)
    this.adminService.updateInicioNot(this.adinicio[0].key, formvalue);
    this.resetForm();  
    }

  enviar(formValue){
    this.adminService.insertInicio(formValue)
  //  console.log(formValue)
  }
  
  getInicioNoticia(){
    this.adminService.getAllInicio().subscribe(noticia =>{
     this.adinicio = noticia;
    })
  }

  resetForm() {
    this.formTemplate.reset();
    this.formTemplate.setValue({
      titulo: '',
      subtitulo: '',
      topico1: '',
      topico2: '',
      topico3: '',
    });
  }


}
