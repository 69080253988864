import { SharedAnimations } from "src/app/shared/animations/shared-animations";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, NgForm } from "@angular/forms";
import { NoticiaPrincipal } from "src/app/interface/noticiaPrincipal";
import { AdminService } from "src/app/service/admin.service";

@Component({
  selector: "app-alteranoticiaprincipal",

  templateUrl: "./alteranoticiaprincipal.component.html",
  styleUrls: ["./alteranoticiaprincipal.component.scss"],

  animations: [SharedAnimations]
})
export class AlteraNoticiaPrincipalComponent implements OnInit {
  
  formTemplate = new FormGroup ({
    titulo: new FormControl (''),
    subtitulo: new FormControl(''),
    topico1: new FormControl(''),
    topico2: new FormControl(''),
    topico3: new FormControl(''),
  })
  noticiaDetalhes: NoticiaPrincipal = new NoticiaPrincipal

  constructor(
    private adminService: AdminService
  ) {}

  ngOnInit() {
    this.noticiaDetalhes = this.adminService.getDetalhes()
  }

  updateInicioNoticia(myForm: NoticiaPrincipal){
   // console.log(myForm)
    this.adminService.updateNoticia(myForm.key, myForm)
  }
}
