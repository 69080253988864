<app-menu></app-menu>

<div class="container">
    <div class="mt-5">
        <h2>Insira aqui as LEIS no portal de Padre Carvalho</h2>
    </div>
    <div>
        <form [formGroup]="formTemplate" (submit)="uploadFile(formTemplate.value)">
            <div class="form-group">
            <label for="pwd">Titulo Principal:</label>
            <input formControlName="titulo" type="text" class="form-control"  id="titulo">
            </div>
            <div class="form-group">
                <label for="pwd">Texto simplicado sobre decreto:</label>
                <input formControlName="texto" type="text" class="form-control"  id="texto">
                </div>
            <div class="row">
                <div class="col">
                  <label for="pwd">Data:</label>
                  <input formControlName="data" type="text" class="form-control" id="data">
                </div>
            </div>
            <div>
                <label for="pwd" class="mt-5">
                  Envie o PDF da LEI por aqui
                </label>
                <div class="">
                  <div class="">
                    <div class="">
                      <label for="file-upload" class="">
                        <!-- <span>Upload a file</span> -->
                        <input type="file"  formControlName="pdfDecreto"class=" " 
                         (change)="upload($event)" accept=".pdf " />
                        <!-- <input formControlName="imagemNoticia" id="file-upload" type="file" class="sr-only"> -->
                      </label>
                      <!-- <p class="pl-1">or drag and drop</p> -->
                    </div>
                    <p class=""> PDF up to 1MB
                    </p>
                  </div>
                </div>
            </div>
            <div class="progress m-5">
                <div class="progress-bar"  role="progressbar"  [style]="valor" >
                  {{progresso}}
                </div>
              </div>
            <div class="mb-4">
              <button type="submit" class="">
                Envie para o Site
              </button>
            </div>
        </form>
    </div>
    <!-- <div class="items-center">
        <mat-progress-bar style="height: 20px;" color="bg-red-800" mode="" [value]="progresso" ></mat-progress-bar>
    </div> -->
    <div *ngIf="statusbaraprogress">
 
        <h2> LEI enviada com SUCESSO! </h2>  
    </div>  
</div>

