import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { Inicio } from 'src/app/interface/inicio';
import { NoticiaPrincipal } from 'src/app/interface/noticiaPrincipal';
import { AdminService } from 'src/app/service/admin.service';

@Component({
  selector: 'app-adnoticiaprincipal',
  templateUrl: './adnoticiaprincipal.component.html',
  styleUrls: ['./adnoticiaprincipal.component.scss']
})
export class AdnoticiaprincipalComponent implements OnInit {
   
  formTemplate = new FormGroup({
    titulo: new FormControl(''),
    texto: new FormControl(''),
    data: new FormControl(''),
    imagemNoticia: new FormControl('', Validators.required),
  })

  caminho: any = null;

  uploadProgress: Observable<number>;

  // variável que recebe porcentagem do progresso do upload
  progresso = "";
  valor = ""
 
  //popap para envio de imagem
  statusbaraprogress:boolean = false;

  downloadURL: Observable<string>;

  uploadState: Observable<string>;

  adinicio: Inicio = new Inicio

  newNoticia: NoticiaPrincipal[]


  constructor(
    private storage: AngularFireStorage,
    private adminService: AdminService,
    private route:Router
  ) { 
  
  }

  ngOnInit(): void {
    this.getNoticias()
    this.ordenaDados()
  }

  upload(event){
    this.caminho = event.target.files[0];
    console.log(this.caminho.name);
  }


  uploadFile(formvalue) {
   // console.log(formvalue)
    const file = this.caminho;
    const filePath = `${"noticias"}/${this.caminho.name}_${new Date().getTime()}`;
    const ref = this.storage.ref(filePath);
    const task = ref.put(file);

    // Observa as alterações percentuais a medida que o arquivo é enviado
    this.uploadProgress = task.percentageChanges();

    this.uploadProgress.subscribe(x=> {
      
        this.progresso = String(x) + "%" ;
        
        this.valor = "width: " + String(x)  +"%"
        if (x ===100){
          this.statusbaraprogress = true;
        } 
      //  console.log(x);
    });
    
    // Devolve a informação quando o download tiver concluído
    task.snapshotChanges().pipe(
      finalize(() => { 
        this.downloadURL = ref.getDownloadURL() 
        ref.getDownloadURL().subscribe((url) => {
          formvalue['noticiaUrl'] = url
          formvalue['id'] = Date.now()
         // console.log(formvalue)
          this.adminService.insertImageDetails(formvalue);
          this.resetForm();
        })
    
      })
      )
      .subscribe()
     this.uploadState = task.snapshotChanges().pipe(map(s => 
      s.state
      ));
  }

  resetForm() {
    this.formTemplate.reset();
    this.formTemplate.setValue({
      titulo: '',
      data: '',
      texto: '',
      imagemNoticia: '',
    });
  this.progresso = "0";
  this.valor = "width: " + 2  +"%"
  this.statusbaraprogress = false
}

getNoticias(){
  this.adminService.getAllNoticias().subscribe(noticias=>{
    noticias.map(dado =>{

    //  console.log(dado)

    })
    //console.log(noticias)
   this.newNoticia = noticias.reverse()
  // console.log(this.newNoticia)
   
  })
}

ordenaDados(){

 /// console.log(this.newNoticia.length)
 
}
// getNovo(){
//   console.log( this.adminService.pegarDados())

// }

alteraNoticia(noticia:NoticiaPrincipal){

  this.adminService.setDetalhes(noticia);
  this.route.navigateByUrl('/alteranoticia')
 // console.log(noticia)

}

deletarNoticia(key:string){

 // alert('DESEJA REALMENTE APAGAR ESTA NOTÍCIA?')
 console.log(key)
 this.adminService.deletarNoticia(key)
}

}
