import {LandingLayoutComponent} from "./shared/components/layouts/landing-layout/landing-layout.component";
import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";

import {AuthLayoutComponent} from "./shared/components/layouts/auth-layout/auth-layout.component";
import { DemosComponent } from "./views/landing/components/demos/demos.component";
import { BlogDetailsComponent } from "./views/landing/components/blog-details/blog-details.component";
import { BlogDetailsPageComponent } from "./views/landing/blog-details-page/blog-details-page.component";





const routes: Routes = [
  {
    path: '', loadChildren: () => import('./views/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: 'sessions', loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
  },
  {
    path: 'adm', loadChildren: () => import('./views/adm/adm.module').then(m => m.AdmModule)
  },
  {
    path: '**', redirectTo: '', pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}