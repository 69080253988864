import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { setupTestingRouter } from '@angular/router/testing';
import { map } from 'rxjs/operators';
import { Decretos } from '../interface/decretos';
import { Inicio } from '../interface/inicio';
import { Licitacao } from '../interface/licitacao';
import { NoticiaPrincipal } from '../interface/noticiaPrincipal';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Leis } from '../interface/leis';
import { Resolucao } from '../interface/resolucao';
import { Portarias } from '../interface/portarias';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private noticias: NoticiaPrincipal;
  private portarias: Portarias;
  private licitacoes: Licitacao;
  private resolucao: Resolucao;
  public not: NoticiaPrincipal ;
  public externo: boolean;

  constructor(
   private db: AngularFireDatabase,
   private auth: AngularFireAuth,
   private router: Router
  ) { }
   
  
  logout(){
    
    this.auth.signOut().then(()=>{
      this.router.navigate(['/']);
    }).catch((error)=> {
      console.log(error);
    })
   
   // console.log("entrou certo");

  }

  insertInicio(noticiaDetails) {
   // console.log(noticiaDetails)
    this.db.list('noticia').push(noticiaDetails);
  }

  insertImageDetails(imageDetails) {
    //console.log(imageDetails)
    this.db.list('noticiaprincipal').push(imageDetails);
  }

  insertDecretos(decretos) {
    //console.log(imageDetails)
    this.db.list('decretos').push(decretos);
  }

  insertLeis(leis) {
    //console.log(imageDetails)
    this.db.list('leis').push(leis);
  }

  insertResolucoes(resolucoes) {
    //console.log(imageDetails)
    this.db.list('resolucoes').push(resolucoes);
  }

  insertPortarias(portarias) {
    //console.log(imageDetails)
    this.db.list('portarias').push(portarias);
  }

  insertLicitacoes(licitacoes) {
    //console.log(imageDetails)
    this.db.list('licitacoes').push(licitacoes);
  }


  getAllInicio() {
    return this.db.list('noticia')
    .snapshotChanges()
    .pipe(
      map(changes => {
         return changes.map(c => ({key: c.payload.key, ...c.payload.val() as Inicio }));
      })
    );
   }

   getAllNoticias() {
    return this.db.list('noticiaprincipal')
    .snapshotChanges()
    .pipe(
      map(changes => {
         return changes.map(c => ({key: c.payload.key, ...c.payload.val() as NoticiaPrincipal }));
      })
    );
   }

  //  getAllNoticiasLinkDireto(ky: string) {
  //   return this.db.list('noticiaprincipal/'+ ky)
  //   .snapshotChanges()
  //   .pipe(
  //     map(changes => {
  //       return changes.map(c => ({ key: c.payload.key, ...c.payload.val() as NoticiaPrincipal }));
  //     })
  //   );
  //  }

  //  getAllNoticiasLinkDireto(key: string) {
  //   console.log(key)
    
  //   return this.db.list('noticiaprincipal/'+ key).valueChanges().subscribe((noticias: NoticiaPrincipal[]) => {
  //     console.log(noticias)
  //     this.not = noticias
  //     //console.log(this.not)
      
  //    })
    
  //  }

     getAllNoticiasLinkDireto(key: string) {
   // console.log(key)
    
    return this.db.object('noticiaprincipal/'+ key).valueChanges().subscribe((noticias: NoticiaPrincipal ) => {
     // console.log(noticias)
      this.not = noticias
      //console.log(this.not)
      
     })
    
   }



   getAllDecretos() {
    return this.db.list('decretos')
    .snapshotChanges()
    .pipe(
      map(changes => {
         return changes.map(c => ({key: c.payload.key, ...c.payload.val() as Decretos }));
      })
    );
   }

   getAllLeis() {
    return this.db.list('leis')
    .snapshotChanges()
    .pipe(
      map(changes => {
         return changes.map(c => ({key: c.payload.key, ...c.payload.val() as Leis }));
      })
    );
   }

   getAllPortarias() {
    return this.db.list('portarias')
    .snapshotChanges()
    .pipe(
      map(changes => {
         return changes.map(c => ({key: c.payload.key, ...c.payload.val() as Portarias }));
      })
    );
   }

   getAllResolucoes() {
    return this.db.list('resolucoes')
    .snapshotChanges()
    .pipe(
      map(changes => {
         return changes.map(c => ({key: c.payload.key, ...c.payload.val() as Resolucao }));
      })
    );
   }


   getAllicitacoes() {
    return this.db.list('licitacoes')
    .snapshotChanges()
    .pipe(
      map(changes => {
         return changes.map(c => ({key: c.payload.key, ...c.payload.val() as Licitacao }));
      })
    );
   }

  


   updateInicioNot(adinicio: string, newNoticia: Inicio){

     this.db.list('noticia').update(adinicio, {titulo: newNoticia.titulo,
     subtitulo: newNoticia.subtitulo,
     topico1: newNoticia.topico1,
     topico2: newNoticia.topico2,
     topico3: newNoticia.topico3

    });
   } 

   setDetalhes(noticia: NoticiaPrincipal){
      this.noticias = noticia;
   }

   setDetalhesPortaria(portaria: Portarias){
    this.portarias = portaria;
   }

   setDetalhesResolucao(resolucao: Resolucao){
    this.resolucao = resolucao;
  }


   setDetalhesLicitacao(licitacao: Licitacao){
    this.licitacoes = licitacao;
 }

   getDetalhes(){
     return this.noticias;
     this.externo = false;
     console.log(this.externo)
   }

   getDetalhesLicitacao(){
    return this.licitacoes;
  }

   getDadosExternos(){
    this.externo = true;
   // console.log(this.externo);
   }

   updateNoticia(idnoticia:string, newNoticia: NoticiaPrincipal ){
    //console.log(newNoticia)
    this.db.list('noticiaprincipal').update(idnoticia, {data: newNoticia.data,
      id: newNoticia.id,
      imagemNoticia: newNoticia.imagemNoticia,
      noticiaUrl: newNoticia.noticiaUrl,
      texto: newNoticia.texto,
      titulo: newNoticia.titulo
    
    })
   }

   updatelicitacao(idlicitacao:string, newLicitacao: Licitacao ){
    //console.log(newNoticia)
    this.db.list('licitacoes').update(idlicitacao, {data: newLicitacao.data,
      id: newLicitacao.id,
      editalUrl: newLicitacao.editalUrl,
      pdfEdital: newLicitacao.pdfEdital,
      texto: newLicitacao.texto,
      titulo: newLicitacao.titulo
    
    })
   }

   updatelei(idlei:string, newLei:Leis ){
    //console.log(newNoticia)
    this.db.list('leis').update(idlei, {
      data: newLei.data,
      id: newLei.id,
      leisUrl: newLei.leisUrl,
      pdfLeis: newLei.pdfLeis,
      texto: newLei.texto,
      titulo: newLei.titulo
    
    })
   }

   updateResolucao(idResolucao:string, newResolucao: Resolucao ){
    //console.log(newNoticia)
    this.db.list('resolucao').update(idResolucao, {data: newResolucao.data,
      id: newResolucao.id,
      resolucaoUrl: newResolucao.resolucaoUrl,
      pdfResolucao: newResolucao.pdfResolucao,
      texto: newResolucao.texto,
      titulo: newResolucao.titulo
    
    })
   }

   updatePortaria(idPortaria:string, newPortaria: Portarias ){
    //console.log(newNoticia)
    this.db.list('portaria').update(idPortaria, {
      data: newPortaria.data,
      id: newPortaria.id,
      portariaUrl: newPortaria.portariasUrl,
      pdfportaria: newPortaria.pdfPortarias,
      texto: newPortaria.texto,
      titulo: newPortaria.titulo
    
    })
   }


   deletarNoticia(key:string){
      this.db.list('noticiaprincipal').remove(key);
   }

   deletarLicitacao(key:string){
    this.db.list('licitacoes').remove(key);
   }

   deletarLeis(key:string){
    this.db.list('leis').remove(key);
   }

   deletarPortaria(key:string){
     this.db.list('portarias').remove(key);
   }

   deletarResolucao(key:string){
     this.db.list('resolucao').remove(key);
   }

   pegarDados(){

    this.db.list('/noticiaprincipal', ref => ref.orderByChild('id')).snapshotChanges()
  }
  
}
