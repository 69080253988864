<div class="container">
    <div class="mt-5">
        <h2>Banner Inicial do Site</h2>
    </div>
    <div>
        <form [formGroup]="formTemplate" (submit)="updateInicioNoticia(formTemplate.value)">
            <div class="form-group">
            <label for="email">Titulo Principal:</label>
            <input formControlName="titulo" type="text" class="form-control" [placeholder]="adinicio[0].titulo"  id="email">
            </div>
            <div class="form-group">
            <label for="pwd">Subtítulo:</label>
            <input formControlName="subtitulo" type="text" class="form-control" [placeholder]="adinicio[0].subtitulo" id="pwd">
            </div>
            <div class="row">
                <div class="col">
                  <label for="pwd">Tópico 1:</label>
                  <input formControlName="topico1" type="text" class="form-control" id="email" [placeholder]="adinicio[0].topico1" name="email">
                </div>
                <div class="col">
                  <label for="pwd">Tópico 2:</label>
                  <input formControlName="topico2" type="text" class="form-control" [placeholder]="adinicio[0].topico2" name="pswd">
                </div>
                <div class="col">
                    <label for="pwd">Tópico 3:</label>
                    <input formControlName="topico3" type="text" class="form-control" [placeholder]="adinicio[0].topico3" name="pswd">
                  </div>
            </div>
            <div class="3">
              <button type="submit" class="btn btn-primary mt-3">Gravar</button>
            </div>
       </form>
    </div>   
</div>