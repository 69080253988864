<app-menu></app-menu>
<section id="intro-wrap" class="intro1Wrap  text-left text-white">
  <div class="container">
    <div class="mt-5">
        <h2>Alterar notícias</h2>
        <p>Preencha todos os campos para a noticia ser alterada corretamente</p>
    </div>
    <div>
        <form #myForm="ngForm" (ngSubmit)="updateInicioNoticia(myForm.value)">
            <div class="form-group">
            <label for="email">Titulo Noticia:</label>
            <input type="text" class="form-control" [ngModel]="noticiaDetalhes.titulo" name="titulo"  id="titulo">
            </div>
            <div class="form-group">
            <label for="pwd">Texto :</label>
            <input type="text" class="form-control" [ngModel]="noticiaDetalhes.texto" name="texto" id="texto">
            </div>

            <div class="form-group">
              <label for="Data">URL imagem</label>
              <input type="text"  class="form-control" id="noticiaUrl" [ngModel]="noticiaDetalhes.noticiaUrl" name="noticiaUrl">
            </div>
            
            <div class="row">
                <div class="col form-group">
                  <label for="data">DATA:</label>
                  <input type="text" class="form-control" id="data" [ngModel]= "noticiaDetalhes.data" name="data">
                </div>
                <div class="col form-group">
                  <label for="id">ID:</label>
                  <input type="text" class="form-control" [ngModel]= "noticiaDetalhes.id" name="id">
                </div>
                <div class="col form-group">
                    <label for="pwd">Imagem Noticia:</label>
                    <input type="text" class="form-control" [ngModel]="noticiaDetalhes.imagemNoticia" name="imagemNoticia">
                </div>
                <div class="col form-group">
                  <label for="pwd">Key:</label>
                  <input type="text" class="form-control" [ngModel]="noticiaDetalhes.key" name="key">
              </div>
            </div>
            <div class="3">
              <button type="submit" class="btn btn-primary mt-3">Gravar</button>
            </div>
       </form>
    </div>   
</div>

</section>
