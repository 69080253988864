import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdmRoutingModule } from './adm-routing.module';
import { PrincipalComponent } from './principal/principal.component';
import { AdinicioComponent } from './adinicio/adinicio.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdnoticiaprincipalComponent } from './adnoticiaprincipal/adnoticiaprincipal.component';
import { AddecretosComponent } from './addecretos/addecretos.component';
import { AdlicitacaoComponent } from './adlicitacao/adlicitacao.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuComponent } from './menu/menu.component';
import { AlteraNoticiaPrincipalComponent } from './alteranoticiaprincipal/alteranoticiaprincipal.component';
import { AlteralicitacaoComponent } from './alteralicitacao/alteralicitacao.component';
import { AdleisComponent } from './adleis/adleis.component';
import { AdmportariasComponent } from './admportarias/admportarias.component';
import { AdresolucaoComponent } from './adresolucao/adresolucao.component';


@NgModule({
  imports: [
    CommonModule,
    AdmRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  declarations: [
    PrincipalComponent, 
    AdinicioComponent, 
    AdnoticiaprincipalComponent, 
    AddecretosComponent, 
    AdlicitacaoComponent, 
    MenuComponent,
    AlteraNoticiaPrincipalComponent,
    AlteralicitacaoComponent,
    AdleisComponent,
    AdmportariasComponent,
    AdresolucaoComponent
  ]

})
export class AdmModule { }
