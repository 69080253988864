<app-menu></app-menu>
<div class="container mb-5">
  
    <div class="mt-5">
        <h2>Noticias do Site</h2>
    </div>
    <div>
        <form [formGroup]="formTemplate" (submit)="uploadFile(formTemplate.value)">
            <div class="form-group">
            <label for="pwd">Titulo Principal:</label>
            <input formControlName="titulo" type="text" class="form-control"  id="titulo">
            </div>
            <div class="form-group">
            <label for="pwd">texto:</label>
            <textarea formControlName="texto" type="text" class="form-control"  id="textarea"></textarea>
            </div>
            <div class="row">
                <div class="col">
                  <label for="pwd">Data:</label>
                  <input formControlName="data" type="text" class="form-control" id="data">
                </div>
            </div>
            <div>
                <label for="pwd" class="mt-5">
                  Envie uma imagem da noticia
                </label>
                <div class="">
                  <div class="">
                    <div class="">
                      <label for="file-upload" class="">
                        <!-- <span>Upload a file</span> -->
                        <input type="file"  formControlName="imagemNoticia"class=" " 
                         (change)="upload($event)" accept=".png, .jpg, .gif, .pdf " />
                        <!-- <input formControlName="imagemNoticia" id="file-upload" type="file" class="sr-only"> -->
                      </label>
                      <!-- <p class="pl-1">or drag and drop</p> -->
                    </div>
                    <p class=""> PNG, JPG, GIF up to 1MB
                    </p>
                  </div>
                </div>
            </div>
            <div class="progress m-5">
              <div class="progress-bar"  role="progressbar"  [style]="valor" >
                {{progresso}}
              </div>
            </div>
            <div class="mb-4">
              <button type="submit" class="">
                Envie para o Site
              </button>
            </div>
        </form>
    </div>
    <!-- <div class="items-center">
        <mat-progress-bar style="height: 20px;" color="bg-red-800" mode="" [value]="progresso" ></mat-progress-bar>
    </div> -->
    <div m-3 *ngIf="statusbaraprogress">
 
        <h2> Noticia enviada com SUCESSO! </h2>  
    </div>  
</div>




<div class=" m-5">
  <h2>Noticias postadas no site:</h2>
  <table class="table table-hover">
    <thead>
      <tr>
        <th>Titulo</th>
        <th>Data</th>
        <th>Edição</th>
      </tr>
    </thead>
    <tbody *ngFor= "let info of newNoticia">
      <tr>
        <td>{{info.titulo}}</td>
        <td>{{info.data}}</td>
        <td><button type="button" (click)="alteraNoticia(info)" class="btn btn-warning">Editar</button></td>
        <td><button type="button" (click)="deletarNoticia(info.key)" class="btn btn-danger">Deletar</button></td>
      </tr>
    </tbody>
  </table>
</div>

