import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { Licitacao } from 'src/app/interface/licitacao';
import { AdminService } from 'src/app/service/admin.service';

@Component({
  selector: 'app-adlicitacao',
  templateUrl: './adlicitacao.component.html',
  styleUrls: ['./adlicitacao.component.scss']
})
export class AdlicitacaoComponent implements OnInit {

  formTemplate = new FormGroup({
    titulo: new FormControl(''),
    texto: new FormControl(''),
    data: new FormControl(''),
    pdfEdital: new FormControl('', Validators.required),
  })

  caminho: any = null;

  uploadProgress: Observable<number>;

  // variável que recebe porcentagem do progresso do upload
  progresso = "";
  valor = ""

  //popap para envio de imagem
  statusbaraprogress:boolean = false;

  downloadURL: Observable<string>;

  uploadState: Observable<string>;

  adlicitacao: Licitacao = new Licitacao

  newLicitacao: Licitacao[]

  constructor(
    private storage: AngularFireStorage,
    private adminService: AdminService,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.getLicitacoes()
  }
  upload(event){
    this.caminho = event.target.files[0];
  }


  uploadFile(formvalue) {
   // console.log(formvalue)
    const file = this.caminho;
    const filePath = `${"licitacoes"}/${this.caminho.name}_${new Date().getTime()}`;
    const ref = this.storage.ref(filePath);
    const task = ref.put(file);

    // Observa as alterações percentuais a medida que o arquivo é enviado
    this.uploadProgress = task.percentageChanges();

    this.uploadProgress.subscribe(x=> {
    
      this.progresso = String(x) + "%" ;  
      
      this.valor = "width: " + String(x)  +"%"
      
      if (x ===100){
          this.statusbaraprogress = true;
        } 
        console.log(x);
    });
    
    // Devolve a informação quando o download tiver concluído
    task.snapshotChanges().pipe(
      finalize(() => { 
        this.downloadURL = ref.getDownloadURL() 
        ref.getDownloadURL().subscribe((url) => {
          formvalue['editalUrl'] = url
          formvalue['id'] = Date.now()
         // console.log(formvalue)
          this.adminService.insertLicitacoes(formvalue);
          this.resetForm();
        })
    
      })
      )
      .subscribe()
     this.uploadState = task.snapshotChanges().pipe(map(s => 
      s.state
      ));
  }

  resetForm() {
    this.formTemplate.reset();
    this.formTemplate.setValue({
      titulo: '',
      texto: '',
      data: '',
    });
    this.progresso = "0";
    this.valor = "width: " + 2  +"%"
    this.statusbaraprogress = false
}

getLicitacoes(){
  this.adminService.getAllicitacoes().subscribe(licit=>{
    licit.map(dado =>{

    //  console.log(dado)

    })
    //console.log(noticias)
   this.newLicitacao = licit.reverse()
  // console.log(this.newNoticia)
   
  })
}


alteraLicitacao(licitacao:Licitacao){

  this.adminService.setDetalhesLicitacao(licitacao);
  this.route.navigateByUrl('/alteralicitacao')
 // console.log(noticia)

}

deletarLicitacao(key:string){

 // alert('DESEJA REALMENTE APAGAR ESTA NOTÍCIA?')
 console.log(key)
 this.adminService.deletarLicitacao(key)
}



}
