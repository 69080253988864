import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddecretosComponent } from './addecretos/addecretos.component';
import { AdinicioComponent } from './adinicio/adinicio.component';
import { AdlicitacaoComponent } from './adlicitacao/adlicitacao.component';
import { AdnoticiaprincipalComponent } from './adnoticiaprincipal/adnoticiaprincipal.component';
import { PrincipalComponent } from './principal/principal.component';
import { MenuComponent } from './menu/menu.component';

//Firebase
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { AlteraNoticiaPrincipalComponent } from './alteranoticiaprincipal/alteranoticiaprincipal.component';
import { AlteralicitacaoComponent } from './alteralicitacao/alteralicitacao.component';
import { AdleisComponent } from './adleis/adleis.component';
import { AdmportariasComponent } from './admportarias/admportarias.component';
import { AdresolucaoComponent } from './adresolucao/adresolucao.component';



const routes: Routes = [
  { path: "principal", component: PrincipalComponent, canActivate: [AngularFireAuthGuard]  },
  { path: "adinicio", component: AdinicioComponent, canActivate: [AngularFireAuthGuard]  },
  { path: "adnoticiaprincipal", component: AdnoticiaprincipalComponent, canActivate: [AngularFireAuthGuard] },
  { path: "addecretos", component: AddecretosComponent, canActivate: [AngularFireAuthGuard]  },
  { path: "adlicitacoes", component: AdlicitacaoComponent, canActivate: [AngularFireAuthGuard]  },
  { path: "alteranoticia", component: AlteraNoticiaPrincipalComponent, canActivate: [AngularFireAuthGuard]  },
  { path: "alteralicitacao", component: AlteralicitacaoComponent, canActivate: [AngularFireAuthGuard]  },
  { path: "adleis", component: AdleisComponent, canActivate: [AngularFireAuthGuard]  },
  { path: "adportarias", component: AdmportariasComponent, canActivate: [AngularFireAuthGuard]  },
  { path: "adresolucao", component: AdresolucaoComponent, canActivate: [AngularFireAuthGuard]  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdmRoutingModule { }
