import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Licitacao } from 'src/app/interface/licitacao';
import { AdminService } from 'src/app/service/admin.service';

@Component({
  selector: 'app-alteralicitacao',
  templateUrl: './alteralicitacao.component.html',
  styleUrls: ['./alteralicitacao.component.scss']
})
export class AlteralicitacaoComponent implements OnInit {
  
  formTemplate = new FormGroup ({
    titulo: new FormControl (''),
    subtitulo: new FormControl(''),
    topico1: new FormControl(''),
    topico2: new FormControl(''),
    topico3: new FormControl(''),
  })
  licitacaoDetalhes: Licitacao = new Licitacao

  constructor(
    private adminService: AdminService

  ) { }

  ngOnInit(): void {
    this.licitacaoDetalhes = this.adminService.getDetalhesLicitacao()
  }
  
  updateLicitacao(myForm: Licitacao){
    // console.log(myForm)
     this.adminService.updatelicitacao(myForm.key, myForm)
   }

}
