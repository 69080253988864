<nav class="navbar navbar-expand-lg navbar-light bg-light">
   
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <a class="nav-item nav-link desable">Área Administrativa <span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link active" routerLink="/adm/adnoticiaprincipal">NOTICIAS</a>
        <a class="nav-item nav-link active" routerLink="/adm/adleis">LEIS</a>
        <a class="nav-item nav-link active" routerLink="/adm/addecretos">DECRETOS</a>
        <a class="nav-item nav-link active" routerLink="/adm/adportarias">PORTARIAS</a>
        <a class="nav-item nav-link active" routerLink="/adm/adlicitacoes">LICITAÇÕES</a>
        <a class="nav-item nav-link active" routerLink="/adm/adresolucao">RESOLUÇÃO</a>
        <button (click)="sair()">SAIR</button>
      </div>
    </div>
  </nav>