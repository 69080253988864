//import { SharedAnimations } from '../../../../shared/animations/shared-animations
import { Component, OnInit,HostListener,HostBinding,Inject, } from '@angular/core';
import { AdminService } from 'src/app/service/admin.service';

//import { getAuth, signOut } from "firebase/auth";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
 // animations:[SharedAnimations]
})
export class MenuComponent implements OnInit {

  constructor(
    private adminService: AdminService
  ) {}

  ngOnInit() {}

  sair(){
    this.adminService.logout();
   
   }
  
}