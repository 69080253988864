import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { Leis } from 'src/app/interface/leis';
import { AdminService } from 'src/app/service/admin.service';

@Component({
  selector: 'app-adleis',
  templateUrl: './adleis.component.html',
  styleUrls: ['./adleis.component.scss']
})
export class AdleisComponent implements OnInit {

  formTemplate = new FormGroup({
    titulo: new FormControl(''),
    texto: new FormControl(''),
    data: new FormControl(''),
    pdfDecreto: new FormControl('', Validators.required),
  })

  caminho: any = null;

  uploadProgress: Observable<number>;

  // variável que recebe porcentagem do progresso do upload
  progresso = "";

  //popap para envio de imagem
  statusbaraprogress:boolean = false;

  downloadURL: Observable<string>;

  uploadState: Observable<string>;

  adLeis: Leis = new Leis;
  valor = "";

  constructor(
    private storage: AngularFireStorage,
    private adminService: AdminService

  ) { }

  ngOnInit(): void {
  }
  upload(event){
    this.caminho = event.target.files[0];
  }


  uploadFile(formvalue) {
   // console.log(formvalue)
    const file = this.caminho;
    const filePath = `${"leis"}/${this.caminho.name}_${new Date().getTime()}`;
    const ref = this.storage.ref(filePath);
    const task = ref.put(file);

    // Observa as alterações percentuais a medida que o arquivo é enviado
    this.uploadProgress = task.percentageChanges();


    this.uploadProgress.subscribe(x=> {
      
      this.progresso = String(x) + "%" ;
      
      this.valor = "width: " + String(x)  +"%"
      if (x ===100){
        this.statusbaraprogress = true;
      } 
    //  console.log(x);
  });
    
    // Devolve a informação quando o download tiver concluído
    task.snapshotChanges().pipe(
      finalize(() => { 
        this.downloadURL = ref.getDownloadURL() 
        ref.getDownloadURL().subscribe((url) => {
          formvalue['leisUrl'] = url
          formvalue['id'] = Date.now()
         // console.log(formvalue)
          this.adminService.insertLeis(formvalue);
          this.resetForm();
        })
    
      })
      )
      .subscribe()
     this.uploadState = task.snapshotChanges().pipe(map(s => 
      s.state
      ));
  }

  resetForm() {
    this.formTemplate.reset();
    this.formTemplate.setValue({
      titulo: '',
      texto: '',
      data: '',
      pdfLeis: '',
    });
  this.progresso = "";
  this.statusbaraprogress = false
}

}
