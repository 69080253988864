export class NoticiaPrincipal {
    data: string;
    imagemNoticia: string;
    noticiaUrl: string;
    linkUrl: string;
    texto: string;
    titulo: string;
    id: string;
    key: string

}